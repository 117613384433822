/* eslint-disable canonical/filename-no-index */
import { snakeCase } from 'snake-case';
import { AnalyticsSubscriber } from '../common';
import { getGTMPlayerBaseEvent } from './gtmPlayer';
import { getGTMPlayerAdBaseEvent } from './gtmPlayerAd';

declare global {
  interface Window {
    dataLayer?: {
      push: (data: Record<string, unknown>) => void;
    };
  }
}

const transformEventName = (eventName: string) => {
  switch (eventName) {
    case 'PlayerTimeshift10sBack':
      return 'player_timeshift_10s_back';
    case 'PlayerTimeshift10sForward':
      return 'player_timeshift_10s_forward';
    case 'Player10sBack':
      return 'player_10s_back';
    case 'Player10sForward':
      return 'player_10s_forward';
    case 'PlayerTimeshiftBackToLive':
      return 'player_timeshift_back-to-live';
    case 'PlayerTimeshiftFromBeginning':
      return 'player_timeshift_from-beginning';
    case 'PlayerSettingsChangeSignLanguage':
      return 'player_settings_change_sign-language';
    case 'PlayerSettingsChangeNewWindow':
      return 'player_settings_change_new-window';
    case 'PlayerSettingsChangeLightsOff':
      return 'player_settings_change_lights-off';
    case 'ShowMainButton':
      return 'show_main-button';
    default:
      return snakeCase(eventName);
  }
};

export interface GtmUserContext {
  user?: {
    id?: string;
  };
}

const getUserForAnalytics = (context: GtmUserContext | null) => {
  if (!context) {
    return {};
  }

  return {
    user: {
      id: context.user?.id,
      logged: !!context.user?.id,
      status: context.user?.id ? 'member' : 'anonymous',
    },
  };
};

export const gtmSubscriber = (): AnalyticsSubscriber => {
  let missingDatalayerLogged = false;
  const fireEvent = (eventName: string, data: object) => {
    if (!window.dataLayer) {
      if (!missingDatalayerLogged) {
        // eslint-disable-next-line no-console
        console.warn({ message: 'GTM Datalayer is missing' });
        missingDatalayerLogged = true;
      }
      return;
    }
    window.dataLayer.push({ event: transformEventName(eventName), ...data });
  };

  return {
    name: 'gtm',
    callback: (event) => {
      switch (event.type) {
        case 'PlayerLoad':
          {
            const base = getGTMPlayerBaseEvent(event.context, event.type);
            fireEvent(event.type, {
              ...base,
              _clear: true,
            });
          }
          break;
        case 'Player10sBack':
        case 'Player10sForward':
        case 'PlayerTimeshift10sBack':
        case 'PlayerTimeshift10sForward':
        case 'PlayerSeekBack':
        case 'PlayerShare':
        case 'PlayerTimeshiftSeekForward':
        case 'PlayerTimeshiftSeekBack':
        case 'PlayerSeekForward':
        case 'PlayerBuffering':
        case 'PlayerProgress':
        case 'PlayerTimeshiftStart':
        case 'PlayerTimeshiftBackToLive':
        case 'PlayerNextAutoCancel':
        case 'PlayerEnd':
        case 'PlayerReplay':
        case 'PlayerPause':
        case 'PlayerResume':
        case 'PlayerIndexStartBar':
        case 'PlayerIndexStartList':
        case 'PlayerBitrateAdaptation':
        case 'PlayerShowDetail':
        case 'PlayerTimeshiftFromBeginning':
        case 'PlayerNextAuto':
        case 'PlayerNextManual':
        case 'PlayerSettingsChangeMute':
        case 'PlayerSettingsChangeSignLanguage':
        case 'PlayerSettingsChangeSubtitles':
        case 'PlayerSettingsChangeAudio':
        case 'PlayerSettingsChangeResolution':
        case 'PlayerSettingsChangeScreenmode':
        case 'PlayerSettingsChangeNewWindow':
        case 'PlayerSettingsChangeCast':
        case 'PlayerSettingsChangeBug':
        case 'PlayerSettingsChangeLightsOff':
        case 'PlayerSettingsChangePlaybackRate':
        case 'PlayerSettingsErrorReport':
        case 'PlayerSettingsOpen':
          fireEvent(event.type, {
            ...getGTMPlayerBaseEvent(event.context, event.type),
            _clear: true,
          });
          break;
        case 'PlayerError':
        case 'PlayerPlaylistError':
          {
            const base = event.context
              ? getGTMPlayerBaseEvent(event.context, event.type)
              : undefined;
            fireEvent(event.type, {
              ...base?.player,
              player: {
                content: {
                  id: event.data.mainContentId,
                },
              },
              error: { message: event.data.error },
              _clear: true,
            });
          }
          break;
        case 'PlayerErrorReport':
          {
            const time = event.context
              ? getGTMPlayerBaseEvent(event.context, event.type).player.time
              : undefined;
            fireEvent(event.type, {
              player: {
                time: { ...time },
                content: {
                  id: event.data.mainContentId,
                },
              },
              error: { message: event.data.error },
            });
          }
          break;
        case 'PlayerPlay':
          {
            const base = getGTMPlayerBaseEvent(event.context, event.type);
            fireEvent(event.type, {
              ...base,
              interaction: event.data.interaction,
            });
          }
          break;
        // player ad events (zatim ne: 'PlayerAdBuffering', 'PlayerAdLoad', 'PlayerAdProgress':
        case 'PlayerAdPlay':
        case 'PlayerAdSkip':
        case 'PlayerAdClick':
        case 'PlayerAdPaused':
        case 'PlayerAdClose':
        case 'PlayerAdAllCompleted':
        case 'PlayerAdEnd':
          fireEvent(event.type, getGTMPlayerAdBaseEvent(event.context, event.type));
          break;

        case 'PlayerAdError': {
          const adEvent = getGTMPlayerAdBaseEvent(event.context, event.type);
          fireEvent(event.type, {
            ...adEvent,
            error: {
              message: event.data.error,
            },
          });
          break;
        }
        // tyto eventy nejsou pouzity na website
        // menu events
        case 'MenuOpen':
        case 'MenuClose':
          fireEvent(event.type, event.data);
          break;
        // footer events
        case 'FooterClick':
          fireEvent(event.type, event.data);
          break;

        // analyticke eventy pouzite na website - spojime je do jednoho bloku
        // a posleme k nim info o userovi
        case 'GeneralCustomEvent':
        case 'GeneralSocial':
        case 'OtherView':
        case 'MenuClick':
        case 'ShowMainButton':
        case 'ShowView':
        case 'ShowSearchResults':
        case 'ShowSearchQuery':
        case 'ShowFiltered':
        case 'ShowAddToFavorites':
        case 'ShowRemoveFromFavorites':
        case 'ShowRemoveUnfinished':
        case 'ShowRemoveAllUnfinished':
        case 'ShowRemoveAllClose':
        case 'ShowRemoveAllCancel':
        case 'ShowRemoveAllModal':
        case 'ListingView':
        case 'ListDisplayAll':
        case 'ListSort':
        case 'HomepageView':
        case 'SearchResultsFiltered':
        case 'SearchView':
        case 'ItemView':
        case 'ItemViewError':
        case 'ItemClick':
        case 'ItemShowDetail':
        case 'ItemShowPlay':
        case 'UserSignupView':
        case 'UserSignupSubmit':
        case 'UserSignupValidation':
        case 'UserSignupValidationUndelivered':
        case 'UserSignupValidationResend':
        case 'UserSignupError':
        case 'UserDashboardView':
        case 'UserNameSetStart':
        case 'UserPasswordSetStart':
        case 'UserPasswordSetNewSubmit':
        case 'UserPasswordSetSuccess':
        case 'UserPasswordSetError':
        case 'UserDeleteStart':
        case 'UserDeleteConfirmation':
        case 'UserDeleteError':
        case 'UserLoginSuccess':
        case 'UserLoginError':
        case 'UserLogout':
          fireEvent(event.type, {
            ...getUserForAnalytics(event.context as GtmUserContext | null),
            ...event.data,
          });
          break;
        default:
      }
    },
  };
};
