import { AnalyticsPlayerContext, PlayerEvents } from '../../PlayerEvent';
import { getBreakpointLabel } from './getBreakpointLabel';
import { GTMPlayerEvent } from './gtmTypes';

const getGTMPlayerContent = (
  context: AnalyticsPlayerContext
): GTMPlayerEvent['player']['content'] => {
  if (context.type === 'VOD') {
    const { gtm } = context;
    return {
      id: gtm.id,
      version: gtm.version,
      live: false,
      title: gtm.title,
      // mame posilat undefined, categories nemame
      categories: undefined,
      published: gtm.published,
      // licenci zatim neposilat
      // licence: {
      //   availability: !context.licenceErrorText,
      //   info: context.licenceErrorText,
      //   locked: false,
      // },
      show: {
        id: context.showId,
        title: context.showTitle,
      },
      // nutno posilat u vod i channel, analytice se pak spatne merguji data
      channel: undefined,
    };
  }
  const { gtm } = context;
  // TODO: vyresit po extra prenosech a doplneni dat od be, ted chodi to, co je v typech
  return {
    id: gtm.id,
    version: context.broadcastingExtra?.versionId,
    live: true,
    title: gtm.title,
    // mame posilat undefined, categories nemame
    categories: undefined,
    published: context.broadcastingExtra?.premiere?.toISOString(),
    show: {
      id: context.broadcastingExtra?.showId,
      title: context.broadcastingExtra?.showTitle,
    },
    channel: context.encoder,
  };
};

export const getGTMPlayerBaseEvent = (
  context: AnalyticsPlayerContext,
  eventName: PlayerEvents['type']
): GTMPlayerEvent => {
  const getLength = () => {
    if (context.type === 'LIVE') {
      if (context.broadcastingExtra?.duration === undefined) {
        return undefined;
      }
      return Math.floor(context.broadcastingExtra.duration);
    }
    return Math.floor(context.duration);
  };

  const vodDuration = context.type === 'VOD' ? Math.floor(context.duration) : 0;

  // Percentage pocitame pro VOD, analytika potrebuje u progress eventy pouze cas po peti
  // procentech odsledovane delky videa. Player sice progress triggeruje ihned, kdyz se posune
  // o dalsich 5% delky videa, ale u kratsich videi se muze stat, ze vysledny progress uz je treba
  // 91%. Analytika potrebuje udaj 90% u progress eventu. U ostatnich eventu pocitame progress
  // v beznem rozliseni - viz. "increment".
  const detailedIncrement = vodDuration ? Math.floor((context.playtime / vodDuration) * 100) : 0;
  const roundedToFivePercentIncrement = vodDuration
    ? Math.floor((Math.floor((context.playtime / vodDuration) * 20) / 20) * 100)
    : 0;
  const increment =
    eventName === 'PlayerProgress' ? roundedToFivePercentIncrement : detailedIncrement;
  const percentage = context.type === 'VOD' ? increment : 0;

  const playtime = Math.floor(context.playtime);

  return {
    page: {
      section: context.origin,
    },
    player: {
      iframe: context.inIframe,
      instance: context.instanceId,
      version: context.version,
      playbackId: context.playbackId,
      type: context.audioOnly ? 'audio' : 'video',
      size: getBreakpointLabel(context.width),
      content: getGTMPlayerContent(context),
      settings: {
        // nezname
        signLanguage: undefined,
        subtitles: context.subtitles,
        audio: context.audiotrack,
        quality: {
          bitrate: context.bitrate,
          resolution: context.quality,
        },
        autoplay: context.autoplay,
        screenmode: context.screenmode,
        newWindow: false,
        cast: context.cast || 'off',
        lightsOff: false,
        playbackRate: context.playbackRate,
      },
      time: {
        length: getLength(),
        // zatim posilame v live undefined - chodi nekonzistentni udaje
        position: context.type === 'LIVE' ? undefined : Math.floor(context.position),
        percentage: context.type === 'LIVE' ? undefined : percentage,
        playtime: playtime >= 0 ? playtime : 0,
        currentTimestamp: Date.now(),
        timeshift: undefined,
      },
    },
    interaction: ![
      'PlayerLoad',
      'PlayerUnload',
      'PlayerBuffering',
      'PlayerProgress',
      'PlayerEnd',
      'PlayerBitrateAdaptation',
      'PlayerNextAuto',
      'PlayerHTMLElement',
      'PlayerError',
      'PlayerPlaylistError',
    ].includes(eventName),
  };
};
